var arr = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon'
  },
  {
    title: 'عقد العمل',
    route: 'contract',
    icon: 'EditIcon'
  },
  {
    title: 'الرحلات',
    route: 'trips',
    icon: 'MapPinIcon',
    per: 'trips'
  },
  {
    title: 'الحجاج',
    icon: 'UsersIcon',
    per: 'users',
    children: [
      {
        title: 'اضافة حاج',
        route: 'users-add'
      },
      {
        title: 'استعراض الحجاج',
        route: 'users'
      },
      {
        title: 'استيراد الحجاج',
        route: 'import'
      },
      {
        title: 'ارسال رسائل للحجاج',
        route: 'send'
      },
    ],
  },
  {
    title: 'توزيع الحجاج',
    icon: 'ShuffleIcon',
    per: 'arrange',
    children: [
      {
        title: 'توزيع على الحافلات',
        route: 'arrange-bus'
      },
      {
        title: 'توزيع على السكن',
        route: 'arrange-camp'
      },
      {
        title: 'حجاج غير موزعين',
        route: 'arrange-problem'
      },
    ],
  },
  {
    title: 'الوظائف/الطلبات',
    icon: 'BriefcaseIcon',
    per: 'jobs',
    children: [
      {
        title: 'استعراض الطلبات',
        route: 'forms'
      },
      {
        title: 'الموافق عليها',
        route: 'accepted-forms'
      },
    ],
  },
  {
    title: 'بطاقات النسك',
    icon: 'CreditCardIcon',
    per: 'cards',
    children: [
      {
        title: 'تسجيل البطاقات',
        route: 'cards-register'
      },
      {
        title: 'تسجيل تسليم البطاقات',
        route: 'cards-done'
      },
    ],
  },
  {
    icon: 'UserXIcon',
    title: 'ازالة سكن الحجاج',
    route: 'reset-camp',
    per: 'reset-camp'
  },
  {
    title: 'الاحصائيات',
    route: 'stats',
    icon: 'PieChartIcon',
    per: 'stats'
  },
  {
    title: 'الحافلات',
    route: 'busses',
    icon: 'TruckIcon',
    per: 'busses'
  },
  {
    title: 'تحضير السكن بالباركود',
    route: 'scan',
    icon: 'SearchIcon',
    per: 'scan'
  },
  {
    title: 'السكن والمخيمات',
    route: 'camps',
    per: 'camps',
    icon: 'HomeIcon'
  },
  {
    title: 'طلبات المجاورة',
    route: 'orders-1',
    per: 'orders',
    icon: 'HelpCircleIcon'
  },
  {
    title: 'طلبات نقطة الانطلاق',
    route: 'orders-2',
    per: 'orders',
    icon: 'HelpCircleIcon'
  },
  {
    title: 'الشكاوي',
    route: 'problems',
    per: 'problems',
    icon: 'MessageSquareIcon'
  },
  {
    title: 'اضافة بلاغ',
    route: 'add-ticket',
    per: 'add-ticket',
    icon: 'AlertCircleIcon'
  },
  {
    title: 'البلاغات',
    route: 'view-tickets',
    per: 'view-tickets',
    icon: 'AlertOctagonIcon'
  },
  {
    title: 'الاخبار',
    route: 'posts',
    per: 'posts',
    icon: 'BookOpenIcon'
  },
  {
    title: 'الاستبيانات',
    route: 'ques',
    icon: 'FileIcon',
    per: 'ques'
  },
  {
    title: 'المشرفين',
    route: 'admins',
    per: 'admins',
    icon: 'ShieldIcon'
  },
  {
    title: 'تقييم المشرفين',
    route: 'rate-all',
    per: 'rate-all',
    icon: 'StarIcon'
  },
  {
    title: 'تقييم مشرفين القسم',
    route: 'rate-section',
    per: 'rate-section',
    icon: 'StarIcon'
  },
  {
    title: 'الإعدادات',
    route: 'settings',
    per: 'settings',
    icon: 'SettingsIcon'
  },
  {
    title: 'الوظائف الإضافية',
    icon: 'CheckCircleIcon',
    per: 'tasks2',
    children: [
      {
        title: 'إسناد المهام',
        route: 'tasks2-users'
      },
      {
        title: 'ادارة المهام',
        route: 'tasks2'
      },
    ],
  },
  {
    title: 'المهام',
    route: 'tasks',
    icon: 'CheckSquareIcon'
  },
  {
    title: 'العهدة',
    route: 'commitments',
    pers: "commitments",
    icon: 'FileIcon'
  },
  {
    title: 'التوعية',
    route: 'awareness',
    pers: "awareness",
    icon: 'BookIcon'
  },
  {
    title: 'صفحة المطورين',
    route: 'devs',
    per: '*',
    icon: 'CodeIcon'
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if(user.pers){
    if(user.pers.includes("*") || user.pers.includes(e.per) || !e.per){
      if(e.route == "contract"){
        if(!user.pers.includes("*")){
          arr2.push(e)
        }
      }else{
        arr2.push(e)
      }
    }
  }else{
    if(e.route != "contract"){
      arr2.push(e)
    }
  }

})

export default arr2
